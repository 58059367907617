import { FC, useMemo } from "react"
import { useRouter } from "next/router"
import { format } from "date-fns"
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Spacer,
  Text,
  Card,
  CardProps,
} from "@chakra-ui/react"
import { CardVariants, TextVariants } from "~/theme/components"
import { getAmenities, getImages, getLocation, getMeta, getPrices } from "~/utils/helpers"
import { useAuth, useUser } from "~/components/hooks"
import Blur from "~/components/helpers/Blur"
import { Image, Tooltip } from "~/components/modules"
import { PropertyCardSaleStatus } from "~/components/helpers/cards/property/property-card-sale-status"
import { PropertyCardFooter } from "~/components/helpers/cards/property/property-card-footer"
import { EsProperty } from "~/generated/graphql"

export interface IPropertyRecentlySoldCard {
  property: EsProperty
  onClick?: (property: EsProperty) => void
  cardProps?: Omit<CardProps, "property" | "onClick">
}
export const PropertyRecentlySoldCard: FC<IPropertyRecentlySoldCard> = ({ property, onClick }) => {
  const { openSignInModal } = useAuth()
  const { user } = useUser()
  const router = useRouter()
  const { slug, images, soldPrice, isSold } = useMemo(() => {
    const { soldPrice, estimatedPrice, estimatedPriceDate, isSold } = getPrices(property)

    return {
      ...getMeta(property),
      ...getLocation(property),
      ...getAmenities(property),
      images: getImages(property),
      soldPrice,
      estimatedPrice,
      isSold,
      estimateDate: estimatedPriceDate
        ? format(new Date(estimatedPriceDate), "MMM yyyy")
        : undefined,
    }
  }, [property])

  const handleOnClick = async () => {
    if (!user && isSold) {
      return openSignInModal()
    }

    if (onClick) {
      return onClick(property)
    }
    return await router.push(`/property/${slug}`)
  }

  return (
    <Card variant={CardVariants.Property} onClick={handleOnClick}>
      {images.length > 0 && (
        <Box height="177px" overflow="hidden" borderTopRadius="lg">
          <Image
            src={images[0]}
            alt="Property"
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
          />
        </Box>
      )}

      <Box padding={4} height="180px">
        <Flex alignItems="start" direction="column" gridGap={2} height="100%">
          <HStack spacing={1} alignItems="center" width="100%">
            {soldPrice && (
              <Blur
                justifyContent="center"
                overlay={
                  <Tooltip
                    hasArrow
                    placement="bottom"
                    label="Real estate boards require you to sign in to see the full details."
                    aria-label="A tooltip"
                    bg="hdGold.500"
                    color="white"
                    fontWeight="bold"
                    borderRadius="md"
                    lineHeight="1"
                    p="2"
                  >
                    <Button
                      variant="ghost"
                      size="sm"
                      height="24px"
                      onClick={async e => {
                        e.stopPropagation()
                        await openSignInModal()
                      }}
                      _hover={{}}
                    >
                      Sign in
                    </Button>
                  </Tooltip>
                }
                blurred={!user}
              >
                <Text variant={TextVariants.heading4}>{`$${soldPrice?.toLocaleString()}`}</Text>
              </Blur>
            )}
            <PropertyCardSaleStatus property={property} />
          </HStack>

          <Divider />
          <Spacer />
          <PropertyCardFooter property={property} showDetails={true} blurAddress={isSold} />
        </Flex>
      </Box>
    </Card>
  )
}
