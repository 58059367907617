import { Text } from "@chakra-ui/layout"
import { Collapse } from "@chakra-ui/react"
import { useState } from "react"

interface ExpandingTextProps {
  text: string
  linesBeforeTruncation?: number
}

// assuming regular text height
// current use cases don't require anything extra
// if/when they do, this would need to become a prop
const lineHeight = 24

const ExpandingText = (props: ExpandingTextProps) => {
  const { text, linesBeforeTruncation = 3 } = props
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <Collapse startingHeight={lineHeight * linesBeforeTruncation} in={expanded}>
        {text}
      </Collapse>
      <Text
        fontWeight={"bold"}
        color="hdGold.full"
        onPointerDown={() => setExpanded(!expanded)}
        cursor={"pointer"}
      >
        {expanded ? "Show Less" : "Show More"}
      </Text>
    </>
  )
}

export default ExpandingText
