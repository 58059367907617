import { FC } from "react"
import { Box, Button, Flex, Spacer, Text, Tooltip, ButtonProps } from "@chakra-ui/react"
import { ButtonVariants, TextVariants } from "~/theme/components"
import { PropertyCardDetails } from "~/components/helpers/cards/property/property-card-details"
import { MapView } from "~/store/map"
import { useAuth, useNavigation, useUser } from "~/components/hooks"
import Blur from "~/components/helpers/Blur"
import { getAmenities, getLocation, getMeta } from "~/utils/helpers"
import { CreaListing, EsProperty, PrivateListing } from "~/generated/graphql"

interface IPropertyCardFooter {
  property: EsProperty | PrivateListing | CreaListing
  showDetails?: boolean
  blurAddress?: boolean

  // @TODO: temporary
  mapButtonProps?: ButtonProps
}

export const PropertyCardFooter: FC<IPropertyCardFooter> = ({
  property,
  showDetails = false,
  blurAddress = false,
  mapButtonProps,
}) => {
  const { openSignInModal } = useAuth()
  const { navigate } = useNavigation()
  const { user } = useUser()
  const { id } = getMeta(property)
  const { address, neighbourhood, city, provinceAbbreviated, coordinates } = getLocation(property)
  const { bedrooms, bathrooms, floorSpace } = getAmenities(property)

  return (
    <Flex width="100%">
      <Box overflow="hidden">
        <Blur
          blurred={blurAddress && !user}
          justifyContent="center"
          overlay={
            <Tooltip
              hasArrow
              placement="bottom"
              label="Real estate boards require you to sign in to see the full details."
              aria-label="A tooltip"
              bg="hdGold.500"
              color="white"
              fontWeight="bold"
              borderRadius="md"
              lineHeight="1"
              p="2"
            >
              <Button
                variant="ghost"
                size="sm"
                height="24px"
                onClick={async e => {
                  e.stopPropagation()
                  await openSignInModal()
                }}
                _hover={{}}
              >
                Sign in
              </Button>
            </Tooltip>
          }
        >
          <Text variant={TextVariants.body2} noOfLines={1}>
            {address}
          </Text>

          <Text
            variant={TextVariants.body4}
            fontWeight="400"
            // color="gray.500"
            noOfLines={1}
            marginY={1}
          >
            {[neighbourhood, city, provinceAbbreviated].filter(Boolean).join(", ")}
          </Text>
          {showDetails && (
            <PropertyCardDetails bedrooms={bedrooms} bathrooms={bathrooms} floorSpace={floorSpace} />
          )}
        </Blur>
      </Box>
      <Spacer />
      <Flex direction="column">
        <Spacer />
        <Button
          height="2rem"
          variant={ButtonVariants.Primary}
          onClick={async e => {
            if (!user && blurAddress) {
              return openSignInModal()
            }

            e.stopPropagation()
            await navigate.map({
              state: {
                view: MapView.MAP,
                viewport: {
                  center: [coordinates.longitude, coordinates.latitude],
                  zoom: [16],
                },
                highlightedFeatures: [id],
              },
            })
          }}
          {...mapButtonProps}
        >
          Map
        </Button>
      </Flex>
    </Flex>
  )
}
