import { FC } from "react"
import { Container, ContainerProps } from "@chakra-ui/react"
import { PrivateListing } from "~/generated/graphql"
import { MultiCarousel } from "~/components/modules"
import { PropertyCard } from "~/components/helpers/cards"

export type ExclusiveListingsProps = {
  privateListings: PrivateListing[]
  containerProps?: ContainerProps
  onMoreClick?: () => void
}

export const ExclusiveListings: FC<ExclusiveListingsProps> = ({
  privateListings,
  containerProps,
  onMoreClick
}) => {
  return (
    <Container
      maxW="100%"
      pt={["15px", "15px", "35px"]}
      px={["0", "0", "4", "10"]}
      {...containerProps}
    >
      <MultiCarousel
        title="HonestDoor Listings On REALTOR.ca"
        link={{
          label: "See More",
          href: "/exclusive-listings",
          onClick: onMoreClick
        }}
        loop={true}
        sizes={{ "3xl": 4, "2xl": 3, xl: 3, lg: 2, md: 2, sm: 1 }}
        showArrows={false}
      >
        {privateListings?.map((privateListing, i) => (
          <PropertyCard.Private key={i} property={privateListing} />
        ))}
      </MultiCarousel>
    </Container>
  )
}
