import { ReactNode } from "react"

import { FlexProps, HStack, StackProps, Text } from "@chakra-ui/layout"
import { TypographyProps } from "@chakra-ui/styled-system"

export type TQuickStatItem = {
  value: string | number
  prefix?: string
  suffix?: string
  leftIcon?: ReactNode
  rightIcon?: ReactNode
}

export type TQuickStatsProps = {
  items: TQuickStatItem[]
  fontSize?: TypographyProps["fontSize"]
  fontWeight?: TypographyProps["fontWeight"]
  spacing?: StackProps["spacing"]
  justify?: FlexProps["justifyContent"]
  showIcons?: boolean
}

const QuickStats = (props: TQuickStatsProps): JSX.Element => {
  const {
    items,
    fontSize = "inherit",
    spacing = "4",
    justify,
    showIcons = true,
    fontWeight = "initial",
  } = props

  const statItems = items
    .filter(item => item.value)
    .map((item, key) => (
      <HStack spacing="1.5" key={key}>
        {showIcons && item.leftIcon && item.leftIcon}
        {item.prefix && <Text>{item.prefix}</Text>}
        <Text>{item.value}</Text>
        {item.suffix && <Text>{item.suffix}</Text>}
        {showIcons && item.rightIcon && item.rightIcon}
      </HStack>
    ))

  if (justify) {
    return (
      <HStack spacing={4} fontSize={fontSize} fontWeight={fontWeight} w="100%">
        {statItems}
      </HStack>
    )
  }

  return (
    <HStack spacing={spacing} fontSize={fontSize} fontWeight={fontWeight}>
      {statItems}
    </HStack>
  )
}

export default QuickStats
