import { FC } from "react";
import { formatDistanceToNowStrict } from "date-fns";
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { BsClockFill } from "react-icons/bs";
import { TextVariants } from "~/theme/components";
import { getPrices, isPrivateListing, isPublicListing, isUnlisted } from "~/utils/helpers";
import { CreaListing, EsProperty, PrivateListing, PrivateListingSaleType } from "~/generated/graphql";

interface IPropertyCardSaleStatus {
  property?: EsProperty | PrivateListing | CreaListing
}
export const PropertyCardSaleStatus: FC<IPropertyCardSaleStatus> = ({ property }) => {
  const { listedPrice, soldPriceDate, isSold } = getPrices(property)
  const timeSinceSold = soldPriceDate
    ? formatDistanceToNowStrict(new Date(soldPriceDate))
    : undefined

  return (
    <Flex alignItems="center" width="100%">
      <Box
        marginLeft={2}
        width="0.5em"
        height="0.5em"
        borderRadius={100}
        {...(isUnlisted(property) && {
          bgColor: isSold ? "red.500" : "gold.500",
        })}
        {...(isPublicListing(property) && {
          bgColor: listedPrice ? "green.500" : "gold.500",
        })}
        {...(isPrivateListing(property) && {
          bgColor: listedPrice ? "green.500" : "gold.500",
        })}
      />
      <Text variant={TextVariants.body3} noOfLines={1} marginLeft={1}>
        {isUnlisted(property) && <>{isSold ? "Sold" : "Not Listed"}</>}
        {isPublicListing(property) && <>{listedPrice ? "For Sale" : "Not Listed"}</>}
        {isPrivateListing(property) && (
          <>
            {listedPrice
              ? (
                <>
                  {property.saleType === PrivateListingSaleType.ForSale && "For Sale" }
                  {property.saleType === PrivateListingSaleType.ForRent && "For Rent" }
                  {property.saleType === PrivateListingSaleType.ForLease && "For Lease" }
                </>
              )
              : "Not Listed"
            }
          </>
        )}
      </Text>
      <Spacer />
      {timeSinceSold && (
        <Flex color="gray.500" alignItems="center">
          <BsClockFill />
          <Text variant={TextVariants.body6} noOfLines={1} color="gray.500" fontWeight="400" ml={2}>
            {timeSinceSold}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
