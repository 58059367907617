import { Icon } from "@chakra-ui/icon"
import { Flex, Text } from "@chakra-ui/layout"
import { FaArrowRight } from "react-icons/fa"
import { ButtonVariants } from "~/theme/components/Button"
import ButtonLink from "../ButtonLink"

interface IButtonPairProps {
  cityName?: string
  cityHref?: string
  neighbourhoodName?: string
  neighbourhoodHref?: string
  provinceAbbr?: string
  buttonVariant?: ButtonVariants
}

const ButtonPair = ({
  cityName,
  cityHref,
  neighbourhoodName,
  neighbourhoodHref,
  provinceAbbr,
  buttonVariant,
}: IButtonPairProps): JSX.Element => {
  const neighbourhoodBtn =
    neighbourhoodName && neighbourhoodHref ? (
      <ButtonLink
        href={neighbourhoodHref}
        passHref
        buttonProps={{
          rightIcon: <Icon as={FaArrowRight} />,
          iconSpacing: "1",
          variant: buttonVariant,
          size: "small",
          fontSize: "16px",
          p: "5px",
          px: "10px",
          width: "fit-content",
          mr: "5px",
          mb: "5px",
        }}
      >
        {neighbourhoodName}
      </ButtonLink>
    ) : neighbourhoodName ? (
      <Text fontWeight="medium">{neighbourhoodName}</Text>
    ) : null

  const cityBtn =
    cityName && cityHref ? (
      <ButtonLink
        href={cityHref}
        passHref
        buttonProps={{
          rightIcon: <Icon as={FaArrowRight} />,
          iconSpacing: "1",
          variant: buttonVariant,
          size: "small",
          fontSize: "16px",
          p: "5px",
          px: "10px",
          width: "fit-content",
          mb: "5px",
          textTransform: "capitalize",
        }}
      >
        {cityName},&nbsp;{provinceAbbr}
      </ButtonLink>
    ) : cityName ? (
      <Text fontWeight="medium">
        {cityName},&nbsp;{provinceAbbr}
      </Text>
    ) : null

  return (
    <Flex w={"100%"} flexWrap={"wrap"}>
      {neighbourhoodBtn}
      {cityBtn}
    </Flex>
  )
}

export default ButtonPair
