import { FC } from "react"
import { format } from "date-fns"
import {
  Button,
  Card,
  Icon,
  Divider,
  Flex,
  HStack,
  Heading,
  Spacer,
  Text,
  VStack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react"
import { FaArrowRight, FaHome, FaMapMarkerAlt } from "react-icons/fa"
import { createUrl, formatCurrency, provinceLongToProvinceAbbr } from "~/utils"
import ButtonPair from "~/components/elements/ButtonPair"
import { ButtonVariants } from "~/theme/components/Button"
import ExpandingText from "~/components/elements/ExpandingText"
import InternalLink from "~/components/elements/InternalLink"
import { MapView } from "~/store/map"
import QuickStats from "~/components/elements/QuickStats"
import { formatStreet } from "~/utils/lib/string"
import { useNavigation } from "~/components/hooks"
import { Permit, Permit2 } from "~/generated/graphql"

interface IPermitCard {
  permit:  Permit | Permit2
  flex: boolean
  showOnlyBody?: boolean
}

export const PermitCard: FC<IPermitCard> = ({ permit, showOnlyBody = false }) => {
  const provinceAbbr = provinceLongToProvinceAbbr(permit?.property?.province)?.toLowerCase()
  const { navigate } = useNavigation()

  const cityHref = permit?.property?.cityName
    ? createUrl({
        variant: "cities",
        city: permit?.property?.cityName,
        provinceAbbr,
      })
    : null

  const neighbourhoodHref = permit?.property?.cityName
    ? createUrl({
        provinceAbbr,
        variant: "cities",
        city: permit?.property?.cityName,
        neighbourhood: permit?.property?.neighbourhoodName,
      })
    : null

  return (
    <Card h="100%" data-cy="permit-card">
      <VStack align="start">
        {showOnlyBody ? null : (
          <>
            <HStack color="hdBlack.500">
              <InternalLink
                display="inline-block"
                _hover={{ color: "hdGold.full" }}
                href={`/property/${permit?.property?.slug}`}
              >
                <Heading noOfLines={1} fontSize="26px" _hover={{ color: "hdGold.full" }}>
                  {formatStreet(permit?.property?.unparsedAddress)}
                </Heading>{" "}
              </InternalLink>
              <Icon fontSize="22px" as={FaArrowRight} />
            </HStack>
            <ButtonPair
              cityHref={cityHref}
              cityName={permit?.property?.cityName}
              neighbourhoodHref={neighbourhoodHref}
              neighbourhoodName={permit?.property?.neighbourhoodName}
              provinceAbbr={provinceAbbr.toUpperCase()}
              buttonVariant={ButtonVariants.WhiteRoundedBorder}
            />
            <QuickStats
              items={[
                permit?.property?.assessmentClass
                  ? {
                      value:
                        permit?.property?.assessmentClass == "Residential"
                          ? "House"
                          : permit?.property?.assessmentClass,
                      leftIcon: <Icon fontSize="14px" color="hdBlack.200" as={FaHome} />,
                    }
                  : null,
              ].filter(v => v)}
              justify="space-between"
              fontSize="16px"
            />
            <Divider />
            <Spacer />
            <Flex justify="space-between" w="100%" alignItems="center">
              {permit?.property?.predictedValue ? (
                <Stat>
                  <StatLabel>{"HonestDoor Price"}</StatLabel>
                  <StatNumber>{formatCurrency(permit?.property?.predictedValue)}</StatNumber>
                  <StatHelpText>
                    {format(new Date(permit?.property?.predictedDate), "MMM yyyy")}
                  </StatHelpText>
                </Stat>
              ) : null}
              <Button
                size="sm"
                leftIcon={<FaMapMarkerAlt />}
                variant="outline"
                colorScheme="hdMoney"
                onClick={() => {
                  navigate.map({
                    state: {
                      viewport: {
                        center: [permit?.property?.location.lon, permit?.property?.location.lat],
                        zoom: [19],
                      },
                      view: MapView.MAP,
                      highlightedFeatures: [permit?.property?.id],
                    },
                  })
                }}
              >
                Map
              </Button>
            </Flex>
            <Divider />
          </>
        )}
        <Flex w="100%" justifyContent="space-between" alignItems="center" pb="1em">
          <Stat>
            <StatLabel>{"Applied Date"}</StatLabel>
            <Text color="hdBlack.400">
              {permit?.appliedDate ? format(new Date(permit?.appliedDate), "PP") : "N/A"}
            </Text>
          </Stat>{" "}
          <Stat>
            <StatLabel>{"Issued Date"}</StatLabel>
            <Text color="hdBlack.400">
              {permit?.issuedDate ? format(new Date(permit?.issuedDate), "PP") : "N/A"}
            </Text>
          </Stat>
        </Flex>
        <Flex w="100%" justifyContent="space-between" alignItems="center" pb="1em">
          <Stat>
            <StatLabel>{"Work Type"}</StatLabel>
            <Text color="hdBlack.400">{permit?.workType ? permit?.workType : "N/A"}</Text>
          </Stat>{" "}
          <Stat>
            <StatLabel>{"Job Category"}</StatLabel>
            <Text color="hdBlack.400">{permit?.jobCategory ? permit?.jobCategory : "N/A"}</Text>
          </Stat>
        </Flex>
        <Flex w="100%" justifyContent="space-between" alignItems="center">
          <Stat>
            <StatLabel>{"Est. Project Cost"}</StatLabel>
            <Text color="hdBlack.400">
              {permit?.estProjectCost ? formatCurrency(permit?.estProjectCost) : "N/A"}
            </Text>
          </Stat>{" "}
          <Stat>
            <StatLabel>{"Total Square Footage"}</StatLabel>
            <Text color="hdBlack.400">{permit?.totalSqFt ? permit?.totalSqFt : "N/A"}</Text>
          </Stat>
        </Flex>
        {permit?.jobDescription ? (
          <>
            <Divider />
            <Spacer />
            <Flex w="100%" justifyContent="space-between" alignItems="center">
              <Stat>
                <StatLabel>{"Job Description"}</StatLabel>
                <ExpandingText text={permit?.jobDescription} />
              </Stat>
            </Flex>
          </>
        ) : null}
      </VStack>
    </Card>
  )
}
