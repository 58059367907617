import { FC } from "react"
import { Divider, HStack, Text } from "@chakra-ui/react"

interface IPropertyDetails {
  bedrooms: number
  bathrooms: number
  floorSpace: number
}
export const PropertyCardDetails: FC<IPropertyDetails> = ({
  bedrooms,
  bathrooms,
  floorSpace,
}) => {
  if (!bedrooms && !bathrooms && !floorSpace) return null

  return (
    <HStack height="1.2em" divider={<Divider orientation="vertical" />}>
      {bedrooms > 0 && (
        <HStack>
          <Text noOfLines={1}>{bedrooms}</Text>
          <Text noOfLines={1}>{"bed" + (bedrooms !== 1 ? "s" : "")}</Text>
        </HStack>
      )}
      {bathrooms > 0 && (
        <HStack>
          <Text noOfLines={1}>{bathrooms}</Text>
          <Text noOfLines={1}>{"bath" + (bathrooms !== 1 ? "s" : "")}</Text>
        </HStack>
      )}
      {/*{floorSpace > 0 && (*/}
      {/*  <HStack>*/}
      {/*    <Text noOfLines={1}>*/}
      {/*      {floorSpace.toLocaleString(undefined, { maximumFractionDigits: 0 })}*/}
      {/*    </Text>*/}
      {/*    <Text noOfLines={1}>sqft</Text>*/}
      {/*  </HStack>*/}
      {/*)}*/}
    </HStack>
  )
}
