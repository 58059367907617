import { FC } from "react"
import {
  IPropertyRecentlySoldCard,
  PropertyRecentlySoldCard,
} from "~/components/helpers/cards/property/property-recently-sold.card"
import {
  IPropertyForSaleCard,
  PropertyForSaleCard,
} from "~/components/helpers/cards/property/property-for-sale.card"
import {
  IPropertyPrivateCard,
  PropertyPrivateCard,
} from "~/components/helpers/cards/property/property-private"

interface StaticComponents {
  RecentlySold?: FC<IPropertyRecentlySoldCard>
  ForSale?: FC<IPropertyForSaleCard>
  Private?: FC<IPropertyPrivateCard>
}

export const PropertyCard: FC & StaticComponents = () => null

PropertyCard.RecentlySold = PropertyRecentlySoldCard
PropertyCard.ForSale = PropertyForSaleCard
PropertyCard.Private = PropertyPrivateCard
