import NextLink, { LinkProps } from "next/link"
import { ReactNode } from "react"

import { Button, ButtonProps } from "@chakra-ui/react"

type ButtonLinkProps = {
  buttonProps?: ButtonProps
  children?: ReactNode
} & LinkProps

const ButtonLink = (props: ButtonLinkProps): JSX.Element => {
  const { buttonProps, children, prefetch = false, ...rest } = props

  return (
    <NextLink prefetch={prefetch} {...rest}>
      <Button
        as="span"
        onClick={e => {
          e.stopPropagation()
        }}
        {...buttonProps}
      >
        {children}
      </Button>
    </NextLink>
  )
}

export default ButtonLink
