import { FC, useMemo } from "react"
import { useRouter } from "next/router"
import { Image } from "~/components/modules"
import { Box, Divider, Flex, HStack, Spacer, Tag, Text, Card, CardProps } from "@chakra-ui/react"
import { Icon } from "@chakra-ui/icon"
import { IoTime } from "react-icons/io5"
import { timeAgo } from "~/utils"
import { ButtonVariants, CardVariants, TextVariants } from "~/theme/components"
import { getAmenities, getImages, getLocation, getMeta, getPrices } from "~/utils/helpers"
import { PropertyCardSaleStatus } from "~/components/helpers/cards/property/property-card-sale-status"
import { PropertyCardFooter } from "~/components/helpers/cards/property/property-card-footer"
import { PropertyCardDetails } from "~/components/helpers/cards/property/property-card-details"
import { CreaListing } from "~/generated/graphql"

export interface IPropertyForSaleCard {
  publicListing: CreaListing
  onClick?: (listing: CreaListing) => void
  cardProps?: Omit<CardProps, "property" | "onClick">
}
export const PropertyForSaleCard: FC<IPropertyForSaleCard> = ({ publicListing, onClick }) => {
  const router = useRouter()
  const { slug, images, listedPrice, bedrooms, bathrooms, floorSpace, createdTimeAgo } =
    useMemo(() => {
      return {
        ...getMeta(publicListing),
        ...getLocation(publicListing),
        ...getPrices(publicListing),
        ...getAmenities(publicListing),
        images: getImages(publicListing),
        createdTimeAgo: publicListing.createdAt ? timeAgo(publicListing.createdAt, "") : undefined,
      }
    }, [publicListing])

  return (
    <Card
      variant={CardVariants.Property}
      onClick={onClick ? () => onClick(publicListing) : () => router.push(`/listing/${slug}`)}
      position="relative"

    >
      <Box borderTopRadius="lg" h="235px" overflow="hidden">
        <Image
          src={images[0]}
          alt="Property"
          wrapperProps={{ style: { display: "initial" } }}
        />

        {createdTimeAgo && (
          <Tag position="absolute" top={0} right={0}>
            <HStack fontSize="sm" spacing="6px">
              <Icon as={IoTime} />
              <Text>{createdTimeAgo}</Text>
            </HStack>
          </Tag>
        )}

        <Box position="absolute" bottom="0" left="0" right="0">
          <Box textColor="white" backgroundColor="rgba(0, 0, 0, 0.4)" padding="8px">
            <Flex alignItems="start" direction="column" gridGap={2} height="100%">
              <HStack spacing={1} alignItems="center" width="100%">
                {listedPrice && (
                  <Text
                    variant={TextVariants.heading4}
                    // color="gold.500"
                  >{`$${listedPrice?.toLocaleString()}`}</Text>
                )}
                <PropertyCardSaleStatus property={publicListing} />
              </HStack>
              <PropertyCardDetails
                bedrooms={bedrooms}
                bathrooms={bathrooms}
                floorSpace={floorSpace}
              />
              <Divider />
              <Spacer />
              <PropertyCardFooter
                property={publicListing}
                mapButtonProps={{
                  variant: ButtonVariants.Secondary,
                  border: "none",
                }}
              />
            </Flex>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
